import { tokens } from "../tokens"

export const pt = {
  [tokens.common.languageChanged]: "Idioma modificado",
  [tokens.common.currency]: "R$",
  [tokens.common.locale]: "pt-BR",
  [tokens.common.currency_code]: "BRL",
  [tokens.nav.requests]: "Pedidos",
  [tokens.nav.concierge]: "Concierges",
  [tokens.nav.concierge]: "Concierges",
  [tokens.nav.condominium]: "Condomínios",
  [tokens.nav.businessPartner]: "Parceiros comerciais",
  [tokens.nav.orderType]: "Tipos de pedido",
  [tokens.nav.event]: "Eventos",
  [tokens.nav.requestStatus]: "Status dos pedidos",
  [tokens.nav.academy]: "Academia",
  [tokens.nav.account]: "Conta",
  [tokens.nav.analytics]: "Análise",
  [tokens.nav.auth]: "Autenticação",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.browse]: "Browse",
  [tokens.nav.calendar]: "Calendário",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.checkout]: "Checkout",
  [tokens.nav.concepts]: "Módulos",
  [tokens.nav.staff]: "Staff",
  [tokens.nav.contact]: "Contato",
  [tokens.nav.course]: "Curso",
  [tokens.nav.create]: "Criar",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.customerMenu]: "Onboarding",
  [tokens.nav.customers]: "Clientes",
  [tokens.nav.users]: "Usuários",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.details]: "Detalhes",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Editar",
  [tokens.nav.error]: "Erro",
  [tokens.nav.feed]: "Feed",
  [tokens.nav.fileManager]: "Gerenciador de arquivos",
  [tokens.nav.finance]: "Financeiro",
  [tokens.nav.fleet]: "Fleet",
  [tokens.nav.forgotPassword]: "Esqueceu sua senha",
  [tokens.nav.invoiceList]: "Faturas - Remover",
  [tokens.nav.paymentList]: "Pagamentos",
  [tokens.nav.jobList]: "Listas de empregos",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.list]: "Dashboard",
  [tokens.nav.login]: "Login",
  [tokens.nav.logout]: "Logout",
  [tokens.nav.billing]: "Conta",
  [tokens.nav.settings]: "Configuração",
  [tokens.nav.profile]: "Perfil",
  [tokens.nav.logistics]: "Logística",
  [tokens.nav.mail]: "Correspondência",
  [tokens.nav.management]: "Gerenciamento",
  [tokens.nav.orderList]: "Pedidos - Old",
  [tokens.nav.overview]: "Visão geral",
  [tokens.nav.pages]: "Páginas",
  [tokens.nav.postCreate]: "Post Create",
  [tokens.nav.postDetails]: "Post Details",
  [tokens.nav.postList]: "Post List",
  [tokens.nav.pricing]: "Preços",
  [tokens.nav.productList]: "Produtos",
  [tokens.nav.profile]: "Perfil",
  [tokens.nav.register]: "Cadastros",
  [tokens.nav.resetPassword]: "Redefinir senha",
  [tokens.nav.socialMedia]: "Mídia social",
  [tokens.nav.verifyCode]: "Código de verificação",

  // Languages
  [tokens.language.languageTitle]: "Idioma",
  [tokens.language.portuguese]: "Português",
  [tokens.language.english]: "Inglês",
  [tokens.language.german]: "Alemão",
  [tokens.language.spanish]: "Espanhol",

  // Pages
  [tokens.page.order]: "Pedidos",
  [tokens.page.orderType]: "Tipo de pedido",
  [tokens.page.orderCreate]: "Criar pedido",
  [tokens.page.orderTypeCreate]: "Criar tipo de pedido",
  [tokens.page.orderTypeEdit]: "Editar tipo de pedido",
  [tokens.page.chat]: "Chat",
  [tokens.page.concierge]: "Concierge",
  [tokens.page.conciergeCreate]: "Cadastrar Concierge",
  [tokens.page.conciergeEdit]: "Editar Concierge",
  [tokens.page.condominium]: "Condomínio",
  [tokens.page.condominiumCreate]: "Cadastrar Condomínio",
  [tokens.page.condominiumEdit]: "Editar Condomínio",
  [tokens.page.businessPartner]: "Parceiro comercial",
  [tokens.page.businessPartnerCreate]: "Cadastrar parceiro comercial",
  [tokens.page.businessPartnerEdit]: "Editar parceiro comercial",
  [tokens.page.businessPartnerService]: "Serviços - parceiro comercial",
  [tokens.page.businessPartnerServiceCreate]: "Criar serviço - parceiro comercial",
  [tokens.page.businessPartnerServiceEdit]: "Editar serviço - parceiro comercial",
  [tokens.page.event]: "Evento",
  [tokens.page.customersEvent]: "Lista de clientes do evento",
  [tokens.page.eventCreate]: "Criar Evento",
  [tokens.page.eventEdit]: "Editar Evento",

  // Common Screen
  [tokens.commonScreen.sortBy]: "Ordenar por",
  [tokens.commonScreen.FilterBy]: "Filtrar por",
  [tokens.commonScreen.searchBy]: "Buscar por",
  [tokens.commonScreen.filters.sortByCreatedNew]: "Criado em (recentes)",
  [tokens.commonScreen.filters.sortByCreatedLast]: "Criado em (antigos)",
  [tokens.commonScreen.filters.searchByDocumentNumber]: "CPF",
  [tokens.commonScreen.filters.searchByRegistrationNumber]: "CNPJ",
  [tokens.commonScreen.filters.searchByServiceProvided]: "Serviço prestado",
  [tokens.commonScreen.filters.FilterByOthers]: "Outros",
  [tokens.commonScreen.filters.searchByEmail]: "E-mail",
  [tokens.commonScreen.filters.searchByOrderType]: "Buscar por Tipo de pedido",
  [tokens.commonScreen.filters.ByDataonScreen]: "dados em tela",
  [tokens.commonScreen.filters.searchByName]: "Nome",
  [tokens.commonScreen.table.header.actions]: "Ações",
  [tokens.commonScreen.table.header.brand]: "Marca",
  [tokens.commonScreen.table.footer.rowsPerPage]: "Linhas por página",
  [tokens.commonScreen.maleGender]: "Masculino",
  [tokens.commonScreen.femaleGender]: "Feminino",
  [tokens.commonScreen.othersGender]: "Outros",
  [tokens.commonScreen.marital_status.single]: "Solteiro(a)",
  [tokens.commonScreen.marital_status.married]: "Casado(a)",
  [tokens.commonScreen.marital_status.widower]: "Viúvo(a)",
  [tokens.commonScreen.marital_status.divorced]: "Divorciado(a)",
  [tokens.commonScreen.validation.invalid_field]: "Campo inválido!",
  [tokens.commonScreen.validation.required_field]: "Campo Obrigatório!",
  [tokens.commonScreen.idNumber]: "RG",
  [tokens.commonScreen.socialSecuityNumber]: "CPF",
  [tokens.commonScreen.driversLicence]: "CNH",
  [tokens.commonScreen.documentNumber]: "CNPJ",
  [tokens.commonScreen.yes]: "Sim",
  [tokens.commonScreen.no]: "Não",
  [tokens.commonScreen.active]: "Ativo",
  [tokens.commonScreen.disabled]: "Dsabilitado",
  [tokens.commonScreen.requiredFiled]: "é um campo obrigatório",
  [tokens.commonScreen.actions.delete]: "Deletar",
  [tokens.commonScreen.actions.edit]: "Editar",
  [tokens.commonScreen.rollback_title]: "Voltar",
  [tokens.commonScreen.btn_cancel]: "Cancelar",
  [tokens.commonScreen.createForm.brand]: "Marca",

  //profile
  [tokens.profileScreen.main_title_profile]: "Detalhes básicos",
  [tokens.profileScreen.btn_create_profile]: "Create User",
  [tokens.profileScreen.table_main.header.picture]: "Picture",
  [tokens.profileScreen.table_main.header.email]: "E-mail",
  [tokens.profileScreen.table_main.header.usertype]: "User Type",
  [tokens.profileScreen.table_main.header.status]: "Status",
  [tokens.profileScreen.table_main.header.lastLogin]: "Last Login",
  [tokens.profileScreen.table_main.header.createAt]: "Create at",
  [tokens.profileScreen.table_main.filters.name]: "Name by",
  [tokens.profileScreen.table_main.filters.status]: "Status",
  [tokens.profileScreen.table_main.filters.empty_state.filter_usertype]: "No filters applied",
  [tokens.profileScreen.table_main.filters.empty_state.text_field]: "Search by user name",
  [tokens.profileScreen.table_main.filters.sort_by_date_new]: "Create at (newest)",
  [tokens.profileScreen.table_main.filters.sort_by_date_last]: "Create at (oldest)",
  [tokens.profileScreen.table_main.filters.sort_by_user_active]: "Sort by user active",
  [tokens.profileScreen.form.general.title_label_picture]: "Sort by userType",
  [tokens.profileScreen.form.general.title_label_full_name]: "Sort by userType",
  [tokens.profileScreen.form.general.title_label_email]: "Sort by userType",
  [tokens.profileScreen.form.general.title_label_email_name]: "Sort by userType",
  [tokens.profileScreen.form.general.btn_edit_name_label]: "Sort by userType",
  [tokens.profileScreen.form.general.title_label_profile]: "Sort by userType",
  [tokens.profileScreen.form.general.title_label_message]: "Sort by userType",
  [tokens.profileScreen.form.notifications.email_label_title]: "Sort by userType",
  [tokens.profileScreen.form.notifications.email_label_title_security]: "Sort by userType",
  [tokens.profileScreen.form.notifications.phone_label_title_security]: "Sort by userType",
  [tokens.profileScreen.form.notifications.phone_label_text_security]: "Sort by userType",
  [tokens.profileScreen.form.security.password_label_title]: "Sort by userType",
  [tokens.profileScreen.form.security.password_label_title_security]: "Sort by userType",
  [tokens.profileScreen.form.security.phone_label_title_security]: "Sort by userType",
  [tokens.profileScreen.form.security.phone_label_text_security]: "Sort by userType",
  [tokens.profileScreen.form.security.phone_label_text_security]: "Sort by userType",
  [tokens.profileScreen.form.security.phone_label_text_security]: "Sort by userType",
  [tokens.profileScreen.form.multifactorAuthentication.Authenticator_label_title]:
    "Sort by userType",
  [tokens.profileScreen.form.multifactorAuthentication.Authenticator_label_title_security]:
    "Sort by userType",
  [tokens.profileScreen.form.multifactorAuthentication.text_label_title_security]:
    "Sort by userType",
  [tokens.profileScreen.form.multifactorAuthentication.text_label_text_security]:
    "Sort by userType",
  // User
  [tokens.userScreen.main_title_user]: "Usuários",
  [tokens.userScreen.btn_create_user]: "Crie um usuário",
  [tokens.userScreen.table_main.header.name]: "Nome",
  [tokens.userScreen.table_main.header.email]: "E-mail",
  [tokens.userScreen.table_main.header.usertype]: "Tipo de usuário",
  [tokens.userScreen.table_main.header.status]: "Status",
  [tokens.userScreen.table_main.header.lastLogin]: "último login",
  [tokens.userScreen.table_main.header.createAt]: "Criado em ",
  [tokens.userScreen.table_main.filters.name]: "Nome",
  [tokens.userScreen.table_main.filters.status]: "Status",
  [tokens.userScreen.table_main.filters.search_user]: "Buscar usuários pelos ",
  [tokens.userScreen.table_main.filters.empty_state.filter_usertype]: "No filters applied",
  [tokens.userScreen.table_main.filters.empty_state.text_field]: "Search by user name",
  [tokens.userScreen.table_main.filters.sort_by_date_new]: "Criado em (mais recentes)",
  [tokens.userScreen.table_main.filters.sort_by_date_last]: "Criado em (Antigos)",
  [tokens.userScreen.table_main.filters.sort_by_user_active]: "Ordernar por usuário ativp",
  [tokens.userScreen.table_main.filters.sort_by_usertype]: "Ordernar por tipo de usuário",
  [tokens.userScreen.table_main.filters.sort_by_usertype_asc]: "Tipo de usuário (A-Z)",
  [tokens.userScreen.table_main.filters.sort_by_usertype_desc]: "Tipo de usuário (Z-A)",
  [tokens.userScreen.form.title_label_detail]: "Detalhes usua´rio",
  [tokens.userScreen.form.user_title_label_edit]: "Edição do Usuário",
  [tokens.userScreen.form.user_title_label_create]: "Criação de Usuários",
  [tokens.userScreen.form.user_id_label]: "Código do usuário",
  [tokens.userScreen.form.request_number_label]: "Nro usuário",
  [tokens.userScreen.form.request_type_label]: "Tipo de usuário",
  [tokens.userScreen.form.request_status_label]: "Status",
  [tokens.userScreen.form.user_label]: "Editar usuário",
  [tokens.userScreen.form.user_name_label]: "Nome",
  [tokens.userScreen.form.user_type_label]: "Tipo do usuário",
  [tokens.userScreen.form.user_gender_label]: "Gênero do usuário",
  [tokens.userScreen.form.user_password_label]: "Senha:********",
  [tokens.userScreen.table_main.filters.filter_by_none]: "None",
  [tokens.userScreen.table_main.filters.filter_by_firstName]: "Primeiro nome",
  [tokens.userScreen.table_main.filters.filter_by_lastName]: "Último Nome",
  [tokens.userScreen.table_main.filters.filter_by_phoneNumber]: "Número de Telefone",
  [tokens.userScreen.table_main.filters.filter_by_customer_email]: "E-mail",
  [tokens.userScreen.form.user_phone_label]: "Phone:(+XX)XX-XXXXX-XXX)",
  [tokens.userScreen.form.user_welcomeNotification_label]: "Data do cadastro",
  [tokens.userScreen.form.user_status_label]: "Ativar usuário",
  [tokens.userScreen.form.user_status_obs_label]:
    "O cliente não pode ser ativado pelo cadastro de usuários",
  [tokens.userScreen.form.user_email_label]: "Email",
  [tokens.userScreen.form.user_document_label]: "Document number",
  [tokens.userScreen.form.total_amount_label]: "Total amount",
  [tokens.userScreen.form.user_last_login_label]: "Last Login",
  [tokens.userScreen.form.btn_edit_label]: "Edit order",
  [tokens.userScreen.form.btn_approve]: "Approve",
  [tokens.userScreen.form.btn_reject]: "Reject",
  [tokens.userScreen.form.btn_close]: "Close",
  [tokens.userScreen.form.btn_chat]: "Chat",
  [tokens.userScreen.form.btn_save]: "Save changes",
  [tokens.userScreen.form.btn_cancel]: "Cancel",
  [tokens.userScreen.form.createForm.create_title]: "New order",
  [tokens.userScreen.form.createForm.btn_create_order]: "Create order",
  [tokens.userScreen.table_main.filters.sort_by_date_new]: "Create at (newest)",
  [tokens.userScreen.table_main.filters.sort_by_date_last]: "Create at (oldest)",
  [tokens.userScreen.form.createForm.sucess_message]: "Order created with success",
  [tokens.userScreen.form.createForm.error_message]: "Something went wrong creating order!",

  // Request screen
  [tokens.requestScreen.main_title]: "Pedidos",
  [tokens.requestScreen.btn_create_request]: "Criar Pedido",
  [tokens.requestScreen.table_main.header.number]: "Nro. Pedido",
  [tokens.requestScreen.table_main.header.client]: "Cliente",
  [tokens.requestScreen.table_main.header.amount]: "Valor do pedido",
  [tokens.requestScreen.table_main.header.concierge]: "Concierge",
  [tokens.requestScreen.table_main.header.createAt]: "Criado em",
  [tokens.requestScreen.table_main.header.paymentStatus]: "Status pagamento",
  [tokens.requestScreen.table_main.header.orderPaymentDate]: "Data de pagamento",
  [tokens.requestScreen.table_main.header.orderType]: "Tipo",
  [tokens.requestScreen.table_main.header.orderElapsed]: "Dias corridos",
  [tokens.requestScreen.table_main.filters.order]: "Ordernar por",
  [tokens.requestScreen.table_main.filters.status]: "Status",
  [tokens.requestScreen.table_main.filters.empty_state.filter_category]: "Nenhum filtro aplicado",
  [tokens.requestScreen.table_main.filters.empty_state.text_field]: "Busque pelo nome do cliente",
  [tokens.requestScreen.table_main.filters.empty_state.orderId_filter]: "Número do pedido",
  [tokens.requestScreen.form.title_label_detail]: "Detalhes do pedido",
  [tokens.requestScreen.form.title_label_edit]: "Editar pedido",
  [tokens.requestScreen.form.id_label]: "ID",
  [tokens.requestScreen.form.request_number_label]: "Nro. do pedido",
  [tokens.requestScreen.form.request_type_label]: "Tipo do pedido",
  [tokens.requestScreen.form.request_businessPartner_label]: "Fornecedor",
  [tokens.requestScreen.form.request_status_label]: "Status",
  [tokens.requestScreen.form.request_payment_status_label]: "Status pagamento",
  [tokens.requestScreen.form.customer_label]: "Cliente",
  [tokens.requestScreen.form.customer_label]: "Cliente",

  [tokens.requestScreen.form.customer_name_label]: "Nome do cliente",
  [tokens.requestScreen.form.customer_phone_label]: "Telefone do cliente",
  [tokens.requestScreen.form.customer_email_label]: "E-mail do cliente",
  [tokens.requestScreen.form.customer_document_label]: "Número de documento",
  [tokens.requestScreen.form.total_amount_label]: "Valor total",
  [tokens.requestScreen.form.request_date_label]: "Data do pedido",
  [tokens.requestScreen.form.closing_date_label]: "Pedido fechado em",
  [tokens.requestScreen.form.payment_label]: "Pagamento",
  [tokens.requestScreen.form.payment_date_label]: "Pedido pago em",
  [tokens.requestScreen.form.payment_type_label]: "Forma de pagamento",
  [tokens.requestScreen.form.aditional_customer_label]: "Dados adicionais do cliente",
  [tokens.requestScreen.form.concierge_label]: "Concierge",
  [tokens.requestScreen.form.order_notes_label]: "Notas do Pedido",
  [tokens.requestScreen.form.order_placeholder_label]:
    "Escreva aqui informações do pedido, as mesmas não irão para o cliente.",
  [tokens.requestScreen.form.businessPartner_label]: "Fornecedor",
  [tokens.requestScreen.form.businessPartnerService_label]: "Serviço",
  [tokens.requestScreen.form.businessPartnerService_name_label]: "Tipo do Serviço",
  [tokens.requestScreen.form.businessPartner_name_label]: "Nome do Fornecedor",
  [tokens.requestScreen.form.businessPartner_email_label]: "E-mail",
  [tokens.requestScreen.form.businessPartner_phone_label]: "Telefone",
  [tokens.requestScreen.form.concierge_name_label]: "Concierge",
  [tokens.requestScreen.form.concierge_email_label]: "E-mail",
  [tokens.requestScreen.form.concierge_phone_label]: "Telefone",
  [tokens.requestScreen.form.concierge_identification_label]: "Nro. Registro",
  [tokens.requestScreen.form.btn_edit_label]: "Editar pedido",
  [tokens.requestScreen.form.btn_approve]: "Aprovar",
  [tokens.requestScreen.form.btn_reject]: "Recusar",
  [tokens.requestScreen.form.btn_close]: "Fechar",
  [tokens.requestScreen.form.btn_chat]: "Chat",
  [tokens.requestScreen.form.btn_save]: "Salvar",
  [tokens.requestScreen.form.btn_cancel]: "Cancelar",
  [tokens.requestScreen.form.btn_complete_by_pix]: "Finalizar pedido com pagamento via Pix",
  [tokens.requestScreen.form.createForm.create_title]: "Novo pedido",
  [tokens.requestScreen.form.createForm.btn_create_order]: "Criar pedido",
  [tokens.requestScreen.table_main.filters.sort_by_date_new]: "Criado em (recentes)",
  [tokens.requestScreen.table_main.filters.sort_by_date_last]: "Criado em (antigos)",
  [tokens.requestScreen.table_main.filters.sort_by_total_amount_max]: "Valor total (maior)",
  [tokens.requestScreen.table_main.filters.sort_by_total_amount_min]: "Valor total (menor)",
  [tokens.requestScreen.form.createForm.sucess_message]: "Pedido criado com sucesso",
  [tokens.requestScreen.form.createForm.error_message]:
    "Erro ao tentar criar pedido, por favor tente novamente mais tarde!",
  [tokens.requestScreen.form.updateFrom.sucess_message]: "Pedido atualizado com sucesso",
  [tokens.requestScreen.form.updateFrom.error_message]:
    "Erro ao tentar atualizar pedido, por favor tente novamente mais tarde!",
  [tokens.requestScreen.form.default_grouped_condominium_name]: "Condomínio não configurado",
  [tokens.requestScreen.form.customer_default_select_option]: "Selecione um cliente",
  [tokens.requestScreen.form.condominium_label]: "Condomínio do cliente",
  [tokens.requestScreen.form.is_recurring_label]: "Pedido recorrente",
  [tokens.requestScreen.form.is_recurring_description_label]:
    "Informa se o pedido que esta sendo criado é um pedido recorrente.",

  // Chat Screen
  [tokens.requestScreen.form.chat.btn_see_order]: "Ver pedido",
  [tokens.requestScreen.form.chat.last_active]: "Última mensagem",
  [tokens.requestScreen.form.chat.send_message]: "Digite sua mensagem",
  [tokens.requestScreen.form.chat.me]: "Você",
  [tokens.requestScreen.form.chat.message.error_attach_file]:
    "Arquivo Inválido! Verifique o formato e/ou tamanho de no maximo 10MB",
  [tokens.requestScreen.form.chat.message.error_upload_file]:
    "Error ao tentar realizar o upload do arquivo: ",
  [tokens.requestScreen.form.chat.message.error_download_file]:
    "Error ao tentar baixar arquivo, verifique sua rede e tente novamente mais tarde.",

  // Concierge Screen
  [tokens.conciergeScreen.main_title_concierge]: "Concierge",
  [tokens.conciergeScreen.tab_option_all_label]: "Todos",
  [tokens.conciergeScreen.tab_option_active_label]: "Ativos",
  [tokens.conciergeScreen.tab_option_inactive_label]: "Inativos",

  [tokens.conciergeScreen.table_main.filters.sort_by_name_asc]: "Nome (A-Z)",
  [tokens.conciergeScreen.table_main.filters.sort_by_name_desc]: "Nome (Z-A)",
  [tokens.conciergeScreen.table_main.filters.search_concierge]: "Buscar concierge por ",

  [tokens.conciergeScreen.table_main.header.id]: "Código",
  [tokens.conciergeScreen.table_main.header.name]: "Nome",
  [tokens.conciergeScreen.table_main.header.cpfDocument]: "CPF",
  [tokens.conciergeScreen.table_main.header.rgDocument]: "RG",
  [tokens.conciergeScreen.table_main.header.email]: "E-mail",
  [tokens.conciergeScreen.table_main.header.phone]: "Telefone",
  [tokens.conciergeScreen.table_main.header.celphone]: "Celular",
  [tokens.conciergeScreen.table_main.header.isActive]: "Ativo",
  [tokens.conciergeScreen.table_main.row.rowDocumentTitle]: "Documentos",
  [tokens.conciergeScreen.table_main.row.rowContactTitle]: "Contatos",
  [tokens.conciergeScreen.btn_create_concierge]: "Criar concierge",
  [tokens.conciergeScreen.form.createForm.create_title]: "Novo concierge",
  [tokens.conciergeScreen.form.editForm.edit_title]: "Atualizar concierge",
  [tokens.conciergeScreen.form.createForm.personalInformation]: "Dados pessoais",
  [tokens.conciergeScreen.form.createForm.contactIntomation]: "Dados de contato",
  [tokens.conciergeScreen.form.createForm.conciergeNameLabel]: "Nome completo",
  [tokens.conciergeScreen.form.createForm.conciergeDocumentNumberLabel]: "CPF",
  [tokens.conciergeScreen.form.createForm.conciergeIDLabel]: "RG",
  [tokens.conciergeScreen.form.createForm.conciergeGenderLabel]: "Gênero",
  [tokens.conciergeScreen.form.createForm.conciergeCellphoneLabel]: "Celular",
  [tokens.conciergeScreen.form.createForm.conciergeEmailLabel]: "E-mail",
  [tokens.conciergeScreen.form.createForm.conciergePhoneLabel]: "Telefone",
  [tokens.conciergeScreen.form.createForm.isActiveLabel]: "Cadastro ativo",
  [tokens.conciergeScreen.form.createForm.btnCreateConcierge]: "Criar concierge",
  [tokens.conciergeScreen.form.createForm.conciergeBreadcrumbsNavigationList]:
    "Lista de concierges",
  [tokens.conciergeScreen.form.createForm.conciergeBreadcrumbsNavigationEdit]:
    "Edição do concierge",

  [tokens.conciergeScreen.form.editForm.btnEditConcierge]: "Atualizar concierge",
  [tokens.conciergeScreen.form.sucess_message]: "Sucesso durante a persistência do dado",
  [tokens.conciergeScreen.form.error_message]: "Ocorreu um erro durante a persistência do dado",

  // Order Type Screen
  [tokens.orderTypeScreen.main_title]: "Tipo de pedido",
  [tokens.orderTypeScreen.table_main.header.id]: "Código",
  [tokens.orderTypeScreen.table_main.header.orderType]: "Tipo do pedido",
  [tokens.orderTypeScreen.table_main.header.createdAt]: "Criado em",
  [tokens.orderTypeScreen.table_main.header.isInactive]: "Desativado",

  [tokens.orderTypeScreen.table_main.filters.isInactive]: "Exibir apenas inativos",

  [tokens.orderTypeScreen.table_main.filters.sort_by_order_type_asc]: "Tipo de pedido (A-Z)",
  [tokens.orderTypeScreen.table_main.filters.sort_by_order_type_desc]: "Tipo de pedido (Z-A)",

  [tokens.orderTypeScreen.btn_create_orderType]: "Criar tipo de pedido",
  [tokens.orderTypeScreen.form.createForm.create_title]: "Tipo de Pedido",
  [tokens.orderTypeScreen.form.editForm.edit_title]: "Atualizar tipo de pedido",
  [tokens.orderTypeScreen.form.createForm.orderType]: "Tipo de pedido",
  [tokens.orderTypeScreen.form.createForm.shortDescription]: "Descrição resumida",
  [tokens.orderTypeScreen.form.createForm.fullDescription]: "Descrição completa",
  [tokens.orderTypeScreen.form.createForm.isInactiveLabel]: "Desativado",
  [tokens.orderTypeScreen.form.createForm.orderTypeDetails]: "Detalhes do tipo do pedido",
  [tokens.orderTypeScreen.form.createForm.btnCreateOrderType]: "Criar tipo de pedido",
  [tokens.orderTypeScreen.form.createForm.btnCancelOrderType]: "Cancelar",

  [tokens.orderTypeScreen.form.createForm.orderTypeBreadcrumbsNavigationList]:
    "Lista de tipos de pedidos",
  [tokens.orderTypeScreen.form.createForm.orderTypeBreadcrumbsNavigationEdit]:
    "Edição do tipo de pedido",

  [tokens.orderTypeScreen.form.editForm.btnEditOrderType]: "Atualizar tipo de pedido",
  [tokens.orderTypeScreen.form.sucess_message]: "Tipo de pedido criado/atualizado com sucesso",
  [tokens.orderTypeScreen.form.error_message]:
    "Ocorreu um erro durante a criação do novo tipo de pedido",

  // Condominium Screem
  [tokens.condominiumScreen.main_title]: "Condomínio",
  [tokens.condominiumScreen.table_main.header.id]: "Código",
  [tokens.condominiumScreen.table_main.header.name]: "Nome",
  [tokens.condominiumScreen.table_main.header.createdAt]: "Criado em",
  [tokens.condominiumScreen.table_main.header.cnpj]: "Cnpj",
  [tokens.condominiumScreen.table_main.header.managerName]: "Gerente",

  [tokens.condominiumScreen.table_main.filters.sort_by_name_asc_con]: "Nome (A-Z)",
  [tokens.condominiumScreen.table_main.filters.sort_by_name_desc_con]: "Nome (Z-A)",
  [tokens.condominiumScreen.table_main.filters.search_condominium]: "Buscar condomínio por ",

  [tokens.condominiumScreen.btn_create_condominium]: "Criar condomínio",
  [tokens.condominiumScreen.form.createForm.create_title]: "Novo condomínio",
  [tokens.condominiumScreen.form.editForm.edit_title]: "Atualizar condomínio",
  [tokens.condominiumScreen.form.createForm.condominiumInformation]: "Informações",
  [tokens.condominiumScreen.form.createForm.condominiumAddress]: "Endereço",
  [tokens.condominiumScreen.form.createForm.notes_label]: "Notas do Condomínio",

  [tokens.condominiumScreen.form.createForm.referralCode]: "Cód.Referência",
  [tokens.condominiumScreen.form.createForm.condominiumNameLabel]: "Condomínio",
  [tokens.condominiumScreen.form.createForm.cnpjDocumentLabel]: "CNPJ",
  [tokens.condominiumScreen.form.createForm.managernameLabel]: "Gerente",
  [tokens.condominiumScreen.form.createForm.managerdocumentLabel]: "Nro. Documento",
  [tokens.condominiumScreen.form.createForm.managerdocumenttypeLabel]: "Tipo documento",
  [tokens.condominiumScreen.form.createForm.address.streetNameLabel]: "Rua",
  [tokens.condominiumScreen.form.createForm.address.neighborhoodLabel]: "Bairro",
  [tokens.condominiumScreen.form.createForm.address.complimentaryInformationLabel]:
    "Informações complementares",
  [tokens.condominiumScreen.form.createForm.address.numberLabel]: "Número",
  [tokens.condominiumScreen.form.createForm.address.zipCodeLabel]: "CEP",
  [tokens.condominiumScreen.form.createForm.address.cityLabel]: "Cidade",
  [tokens.condominiumScreen.form.createForm.address.stateLabel]: "Estado",
  [tokens.condominiumScreen.form.createForm.address.countryLabel]: "País",

  [tokens.condominiumScreen.form.createForm.btnCreateCondominium]: "Criar condomínio",
  [tokens.condominiumScreen.form.createForm.btnCancelCondominium]: "Cancelar",

  [tokens.condominiumScreen.form.createForm.condominiumBreadcrumbsNavigationList]:
    "Lista de condomínios",
  [tokens.condominiumScreen.form.createForm.condominiumBreadcrumbsNavigationEdit]:
    "Editar condomínio",

  [tokens.condominiumScreen.form.editForm.btnEditCondominium]: "Atualizar condomínio",
  [tokens.condominiumScreen.form.sucess_message]: "Condomínio criado/atualizado com sucesso",
  [tokens.condominiumScreen.form.error_message]: "Ocorreu um erro ao inserir o novo condomínio",

  // Business Partner Screem
  [tokens.businessPartnerScreen.main_title]: "Parceiro comercial",
  [tokens.businessPartnerScreen.table_main.header.id]: "Código",
  [tokens.businessPartnerScreen.table_main.header.name]: "Nome",
  [tokens.businessPartnerScreen.table_main.header.homeService]: "Atende presencial",
  [tokens.businessPartnerScreen.table_main.header.createdAt]: "Criado em",
  [tokens.businessPartnerScreen.table_main.header.cnpj]: "Cnpj",
  [tokens.businessPartnerScreen.table_main.header.serviceProvided]: "Serviço prestado",
  [tokens.businessPartnerScreen.table_main.header.amoutProvidedServices]: "Qtde serviços",
  [tokens.businessPartnerScreen.table_main.filters.homeService]: "Atende presencial",
  [tokens.businessPartnerScreen.table_main.filters.search_businessPartner]:
    "Buscar parceiro comercial por ",

  [tokens.businessPartnerScreen.table_main.filters.sort_by_name_asc_bp]: "Nome (A-Z)",
  [tokens.businessPartnerScreen.table_main.filters.sort_by_name_desc_bp]: "Nome (Z-A)",

  [tokens.businessPartnerScreen.btn_create_businessPartner]: "Criar parceiro comercial",
  [tokens.businessPartnerScreen.form.createForm.create_title]: "Novo parceiro comercial",
  [tokens.businessPartnerScreen.form.notes_label]: "Notas do Parceiro comercial",
  [tokens.businessPartnerScreen.form.editForm.edit_title]: "Atualizar parceiro comercial",
  [tokens.businessPartnerScreen.form.createForm.businessPartnerInformation]: "Informações",
  [tokens.businessPartnerScreen.form.createForm.businessPartnerNameLabel]: "Parceiro comercial",
  [tokens.businessPartnerScreen.form.createForm.businessPartnerAddress]: "Endereço",
  [tokens.businessPartnerScreen.form.createForm.businessPartnerContact]: "Contato",
  [tokens.businessPartnerScreen.form.createForm.cnpjDocumentLabel]: "CNPJ",
  [tokens.businessPartnerScreen.form.createForm.address.streetNameLabel]: "Rua",
  [tokens.businessPartnerScreen.form.createForm.address.neighborhoodLabel]: "Bairro",
  [tokens.businessPartnerScreen.form.createForm.address.complimentaryInformationLabel]:
    "Informações complementares",
  [tokens.businessPartnerScreen.form.createForm.address.numberLabel]: "Número",
  [tokens.businessPartnerScreen.form.createForm.address.zipCodeLabel]: "CEP",
  [tokens.businessPartnerScreen.form.createForm.address.cityLabel]: "Cidade",
  [tokens.businessPartnerScreen.form.createForm.address.stateLabel]: "Estado",
  [tokens.businessPartnerScreen.form.createForm.address.countryLabel]: "País",
  [tokens.businessPartnerScreen.form.createForm.contact.phoneNumber]: "Número telefone",
  [tokens.businessPartnerScreen.form.createForm.contact.email]: "Email",
  [tokens.businessPartnerScreen.form.createForm.contact.talkTo]: "Falar com",
  [tokens.businessPartnerScreen.form.createForm.homeService]: "Atende presencial",
  [tokens.businessPartnerScreen.form.createForm.pixkey]: "Chave Pix",
  [tokens.businessPartnerScreen.form.createForm.serviceProvided]: "Serviço prestado",
  [tokens.businessPartnerScreen.form.createForm.btnCreateBusinessPartner]:
    "Criar parceiro comercial",
  [tokens.businessPartnerScreen.form.createForm.btnCancelBusinessPartner]: "Cancelar",

  [tokens.businessPartnerScreen.form.createForm.bpBreadcrumbsNavigationList]:
    "Lista de parceiros comerciais",
  [tokens.businessPartnerScreen.form.createForm.bpBreadcrumbsNavigationEdit]:
    "Editar parceiros comerciais",

  [tokens.businessPartnerScreen.form.editForm.btnEditBusinessPartner]:
    "Atualizar parceiro comercial",
  [tokens.businessPartnerScreen.form.sucess_message]:
    "Parceiro comercial criado/atualizado com sucesso",
  [tokens.businessPartnerScreen.form.error_message]:
    "Ocorreu um erro ao inserir o novo parceiro comercial",

  // Business Partner Screem service
  [tokens.businessPartnerServiceScreen.main_title]: "Serviços",
  [tokens.businessPartnerServiceScreen.table_main.header.id]: "Código",
  [tokens.businessPartnerServiceScreen.table_main.header.service]: "Serviço",
  [tokens.businessPartnerServiceScreen.table_main.header.description]: "Descrição",
  [tokens.businessPartnerServiceScreen.table_main.header.takenRate]: "% Presto",
  [tokens.businessPartnerServiceScreen.table_main.header.createdAt]: "Criado em",
  [tokens.businessPartnerServiceScreen.btn_create_businessPartnerServiceScreen]: "Criar serviço",
  [tokens.businessPartnerServiceScreen.form.createForm.create_title]: "Novo serviço",
  [tokens.businessPartnerServiceScreen.form.editForm.edit_title]: "Atualizar serviço",
  [tokens.businessPartnerServiceScreen.form.createForm.serviceInformation]: "Detalhes do serviço",
  [tokens.businessPartnerServiceScreen.form.createForm.service]: "Serviço",
  [tokens.businessPartnerServiceScreen.form.createForm.description]: "Descrição",
  [tokens.businessPartnerServiceScreen.form.createForm.takenRate]: "% Presto",
  [tokens.businessPartnerServiceScreen.form.createForm.createdAt]: "Criado em",
  [tokens.businessPartnerServiceScreen.form.createForm.updatedAt]: "Atualizado em",
  [tokens.businessPartnerServiceScreen.form.createForm.bpsBreadcrumbsNavigationList]:
    "Lista de serviços",
  [tokens.businessPartnerServiceScreen.form.createForm.bpsBreadcrumbsNavigationEdit]:
    "Editar serviço",

  [tokens.businessPartnerServiceScreen.form.createForm.btnCreateBusinessPartnerService]:
    "Criar serviço",
  [tokens.businessPartnerServiceScreen.form.createForm.btnCancelBusinessPartnerService]: "Cancelar",
  [tokens.businessPartnerServiceScreen.form.editForm.btnEditBusinessPartnerService]:
    "Atualizar serviço",
  [tokens.businessPartnerServiceScreen.form.sucess_message]:
    "Serviço - parceiro comercial criado/atualizado com sucesso",
  [tokens.businessPartnerServiceScreen.form.error_message]:
    "Ocorreu um erro ao inserir o novo serviço do parceiro comercial",
  [tokens.businessPartnerServiceScreen.businessPartnerServiceToolTip]: "Serviços ofertados",

  // Payment Screem Service
  [tokens.paymentReportScreen.main_title]: "Pagamentos",
  [tokens.paymentReportScreen.btn_filter_paymentReportScreen]: "Filtros",
  [tokens.paymentReportScreen.btn_closePdf_paymentReportScreen]: "Fechar",
  [tokens.paymentReportScreen.btn_preview_paymentReportScreen]: "Visualizar",
  [tokens.paymentReportScreen.btn_download_paymentReportScreen]: "Download",
  [tokens.paymentReportScreen.placeHolder_filter_orderId_paymentReportScreen]: "Número do pedido",
  [tokens.paymentReportScreen.label_filter_fromCustomer_paymentReportScreen]: "Cliente",
  [tokens.paymentReportScreen.label_filter_PaidOnly_paymentReportScreen]: "Exibir apenas pagos",
  [tokens.paymentReportScreen.label_filter_GrooupByStatus_paymentReportScreen]:
    "Agrupar por status",
  [tokens.paymentReportScreen.pdf_name]: "Ordem-",
  [tokens.paymentReportScreen.table_main.header.orderDate]: "Data pedido",
  [tokens.paymentReportScreen.table_main.header.paymentDate]: "Data pagamento",
  [tokens.paymentReportScreen.label_filter_orderDateFrom_paymentReportScreen]: "De",
  [tokens.paymentReportScreen.label_filter_orderDateTo_paymentReportScreen]: "Para",
  [tokens.paymentReportScreen.status.pending]: "Pendente",
  [tokens.paymentReportScreen.status.paid]: "Pago",
  [tokens.paymentReportScreen.status.canceled]: "Cancelado",
  [tokens.paymentReportScreen.status.refund]: "Devolvido",
  [tokens.paymentReportScreen.label_summary_from_paymentReportScreen]: "de",
  [tokens.paymentReportScreen.label_summary_payments_paymentReportScreen]: "pagamentos",

  [tokens.paymentReportScreen.details.orderDate]: "Data do pedido",
  [tokens.paymentReportScreen.details.paymentDate]: "Data de pagamento",
  [tokens.paymentReportScreen.details.orderNumber]: "Número do pedido",
  [tokens.paymentReportScreen.details.orderType]: "Tipo do pedido",
  [tokens.paymentReportScreen.details.paidBy]: "Pago por",
  [tokens.paymentReportScreen.details.assistedBy]: "Atendido por",
  [tokens.paymentReportScreen.details.description]: "Descrição",
  [tokens.paymentReportScreen.details.qty]: "Qtde",
  [tokens.paymentReportScreen.details.price]: "Preço",
  [tokens.paymentReportScreen.details.takenRate]: "% Presto",
  [tokens.paymentReportScreen.details.total]: "Total",
  [tokens.paymentReportScreen.details.subTotal]: "Sub total",
  [tokens.paymentReportScreen.details.fatura]: "Fatura",
  [tokens.paymentReportScreen.details.paymentType.type]: "Tipo de pagamento",

  // Event Screem
  [tokens.eventScreen.main_title]: "Evento",
  [tokens.eventScreen.table_main.header.id]: "Código",
  [tokens.eventScreen.table_main.header.shortEventDescription]: "Evento",

  [tokens.eventScreen.table_main.filters.sort_by_name_asc_ev]: "Descrição (A-Z)",
  [tokens.eventScreen.table_main.filters.sort_by_name_desc_ev]: "Descrição (Z-A)",

  [tokens.eventScreen.eventCustomersToolTip]: "Lista de clientes",
  [tokens.eventScreen.tab_option_free_label]: "Gratuito",
  [tokens.eventScreen.tab_option_paid_label]: "Pago",
  [tokens.eventScreen.table_main.header.eventDate]: "Data do evento",
  [tokens.eventScreen.table_main.header.eventValue]: "Valor",
  [tokens.eventScreen.table_main.header.createdAt]: "Criado em",
  [tokens.eventScreen.table_main.header.isFree]: "Gratuito",
  [tokens.eventScreen.table_main.header.maximumCustomers]: "Limite de clientes",
  [tokens.eventScreen.table_main.header.amountOfCustomer]: "Clientes registrados",
  [tokens.eventScreen.form.createForm.maximumCustomers]: "Limite máximo de clientes",
  [tokens.eventScreen.btn_create_event]: "Criar evento",
  [tokens.eventScreen.form.createForm.create_title]: "Novo evento",
  [tokens.eventScreen.form.editForm.edit_title]: "Atualizar evento",
  [tokens.eventScreen.form.createForm.eventInformation]: "Informações",
  [tokens.eventScreen.form.createForm.eventAddress]: "Endereço",

  [tokens.eventScreen.form.createForm.shortEventDescriptionLabel]: "Descrição resumida do evento",
  [tokens.eventScreen.form.createForm.fullEventDescriptionLabel]: "Detalhes sobre o evento",
  [tokens.eventScreen.form.createForm.eventDateLabel]: "Data do evento",
  [tokens.eventScreen.form.createForm.eventValueLabel]: "Valor do evento",
  [tokens.eventScreen.form.createForm.isFreeLabel]: "Evento Gratuito",
  [tokens.eventScreen.form.createForm.address.streetNameLabel]: "Rua",
  [tokens.eventScreen.form.createForm.address.neighborhoodLabel]: "Bairro",
  [tokens.eventScreen.form.createForm.address.complimentaryInformationLabel]:
    "Informações complementares",
  [tokens.eventScreen.form.createForm.address.numberLabel]: "Número",
  [tokens.eventScreen.form.createForm.address.zipCodeLabel]: "CEP",
  [tokens.eventScreen.form.createForm.address.cityLabel]: "Cidade",
  [tokens.eventScreen.form.createForm.address.stateLabel]: "Estado",
  [tokens.eventScreen.form.createForm.address.countryLabel]: "País",

  [tokens.eventScreen.form.createForm.eventBreadcrumbsNavigationList]: "Lista de eventos",
  [tokens.eventScreen.form.createForm.eventBreadcrumbsNavigationEdit]: "Edição do evento",

  [tokens.eventScreen.form.filters.searchByEvent]: "Buscar por descrição evento",
  [tokens.eventScreen.form.filters.isFreeLabel]: "Eventos gratuitos",

  [tokens.eventScreen.form.createForm.btnCreateEvent]: "Criar evento",
  [tokens.eventScreen.form.createForm.btnCancelEvent]: "Cancelar",
  [tokens.eventScreen.form.editForm.btnEditEvent]: "Atualizar evento",
  [tokens.eventScreen.form.sucess_message]: "Evento criado/atualizado com sucesso",
  [tokens.eventScreen.form.error_message]: "Ocorreu um erro ao inserir o novo evento",
  [tokens.notification.title]: "Nova mensagem",
  [tokens.notification.messageTypeDocument]: "Novo documento enviado",
  [tokens.notification.messageTypeImage]: "Nova imagem enviada",
  [tokens.notification.locale]: "pt_BR",
  [tokens.notification.dashboard.title]: "Notificações",
  [tokens.notification.dashboard.not_found]: "Não há notificações",

  //customer evet
  [tokens.customerEventScreen.main_title]: "Lista de clientes",
  [tokens.customerEventScreen.table_main.header.name]: "Cliente",
  [tokens.customerEventScreen.table_main.header.createdAt]: "Registrado em",
  [tokens.customerEventScreen.cvBreadcrumbsNavigationList]: "Lista de eventos",
  [tokens.customerEventScreen.cvBreadcrumbsNavigationEdit]: "Clientes",
  // Customer
  [tokens.customerScreen.main_title]: "Clientes",
  [tokens.customerScreen.last_customer_details]: "Últimos clientes cadastrados",
  [tokens.customerScreen.customer_created_at]: "Ingressou em: ",
  [tokens.customerScreen.dashboard_customer_details]: "Dashboard: Detalhes do cliente",
  [tokens.customerScreen.import_label]: "Importar",
  [tokens.customerScreen.export_label]: "Exportar",
  [tokens.customerScreen.customer_id]: "Código do cliente",
  [tokens.customerScreen.form.editForm.title]: "Editar cliente",
  [tokens.customerScreen.table_main.filters.search]: "Buscar cliente pelos ",
  [tokens.customerScreen.table_main.filters.sort_by_update_new]: "Atualizado (recentes)",
  [tokens.customerScreen.table_main.filters.sort_by_update_last]: "Atualizado (antigos)",
  [tokens.customerScreen.table_main.filters.sort_by_total_asc]: "Total de pedidos (maior)",
  [tokens.customerScreen.table_main.filters.sort_by_total_desc]: "Total de pedidos (menor)",
  [tokens.customerScreen.table_main.filters.filter_by_none]: "Outros",
  [tokens.customerScreen.table_main.filters.filter_by_firstName]: "Nome",
  [tokens.customerScreen.table_main.filters.filter_by_lastName]: "Último Nome",
  [tokens.customerScreen.table_main.filters.filter_by_condominiumName]: "Condomínio",
  [tokens.customerScreen.table_main.filters.filter_by_documentNumber]: "Documento",
  [tokens.customerScreen.table_main.filters.filter_by_customer_email]: "E-mail",
  [tokens.customerScreen.table_main.header.name]: "Nome",
  [tokens.customerScreen.table_main.header.location]: "Localização",
  [tokens.customerScreen.table_main.header.status]: "Status",
  [tokens.customerScreen.table_main.header.orders]: "Pedidos",
  [tokens.customerScreen.table_main.header.spent]: "Valor Pago",
  [tokens.customerScreen.table_main.filters.all]: "Todos",
  [tokens.customerScreen.table_main.filters.pending_approval]: "Aguardando aprovação",
  [tokens.customerScreen.table_main.filters.accepts_markenting]: "Aceita marketing",
  [tokens.customerScreen.details.customer_detail_column]: "Detalhes",
  [tokens.customerScreen.details.customer_address_column]: "Endereços",
  [tokens.customerScreen.details.customer_contacts_column]: "Contatos",
  [tokens.customerScreen.details.customer_invoice_column]: "Faturas",
  [tokens.customerScreen.details.detail_title]: "Informações básicas",
  [tokens.customerScreen.details.email_label]: "E-mail",
  [tokens.customerScreen.details.phone_label]: "Telefone",
  [tokens.customerScreen.details.country_label]: "País",
  [tokens.customerScreen.details.state_label]: "Estado",
  [tokens.customerScreen.details.address_label_1]: "Endereço principal",
  [tokens.customerScreen.details.address_label_2]: "Endereço secundário",
  [tokens.customerScreen.details.condominium_label]: "Condomínio",
  [tokens.customerScreen.details.btn_reset_password]: "Redefinir senha",
  [tokens.customerScreen.address.address_title]: "Endereços",
  [tokens.customerScreen.address.address_table.id_column]: "Código",
  [tokens.customerScreen.address.address_table.street_name_column]: "Endereço",
  [tokens.customerScreen.address.address_table.neighborhood_column]: "Bairro",
  [tokens.customerScreen.address.address_table.zipcode_column]: "Cep",
  [tokens.customerScreen.address.address_table.state_column]: "Estado",
  [tokens.customerScreen.address.address_table.capital_column]: "Capital",
  [tokens.customerScreen.address.address_table.date_column]: "Data de criação",
  [tokens.customerScreen.payments.payment_title]: "Faturas",
  [tokens.customerScreen.payments.payment_table.payment_id_column]: "Nrº Pagamento",
  [tokens.customerScreen.payments.payment_table.order_id_column]: "Nrº Pedido",
  [tokens.customerScreen.payments.payment_table.payment_date_column]: "Data do pagamento",
  [tokens.customerScreen.payments.payment_table.created_date_column]: "Data de criação",
  [tokens.customerScreen.payments.payment_table.gross_value_column]: "Bruto total",
  [tokens.customerScreen.payments.payment_table.net_value_column]: "Líquido total",
  [tokens.customerScreen.payments.payment_table.fee_column]: "Taxa total",
  [tokens.customerScreen.payments.payment_table.status_column]: "Status",
  [tokens.customerScreen.payments.payment_table.action_column]: "Ações",
  [tokens.customerScreen.payments.summary.summary_title]: "Resumo financeiro",
  [tokens.customerScreen.payments.summary.amount_orders]: "Quantidade de pedidos",
  [tokens.customerScreen.payments.summary.gross_value_total]: "Valor bruto total",
  [tokens.customerScreen.payments.summary.net_value_total]: "Valor líquido total",
  [tokens.customerScreen.payments.summary.fee_total]: "Taxa total",
  [tokens.customerScreen.payments.summary.last_order_date]: "Data do último pedido",
  [tokens.customerScreen.payments.payment_paid]: "Pago",
  [tokens.customerScreen.payments.payment_pending]: "Pendente",
  [tokens.customerScreen.email.email_title]: "E-mails",
  [tokens.customerScreen.email.btn_send_mail]: "Enviar e-mail",
  [tokens.customerScreen.email.options.resend_last_invoice]: "Enviar última fatura",
  [tokens.customerScreen.email.options.send_password_reset]: "Enviar e-mail para redefinir senha",
  [tokens.customerScreen.email.options.send_verification]: "Enviar e-mail de verificação",
  [tokens.customerScreen.data_management.title]: "Gestão de dados",
  [tokens.customerScreen.data_management.description]:
    "Remover os dados deste cliente se ele solicitou isso. Após a ação o cliente não terá mais acesso ao aplicativo.",
  [tokens.customerScreen.data_management.btn_delete_account]: "Deletar Conta",
  [tokens.customerScreen.status_active]: "Ativo",
  [tokens.customerScreen.status_enabled]: "Inativo",
  [tokens.customerScreen.form.first_name]: "Primeiro nome",
  [tokens.customerScreen.form.last_name]: "Último nome",
  [tokens.customerScreen.form.condominum_name]: "Condomínio",
  [tokens.customerScreen.form.country_name]: "País",
  [tokens.customerScreen.form.state_name]: "Estado",
  [tokens.customerScreen.form.city_name]: "Cidade",
  [tokens.customerScreen.form.email_name]: "E-mail",
  [tokens.customerScreen.form.document_name]: "CPF",
  [tokens.customerScreen.form.birthday_name]: "Data de Aniversário",
  [tokens.customerScreen.form.phone_name]: "Número de Telefone",
  [tokens.customerScreen.form.marital_status_name]: "Estado Civil",
  [tokens.customerScreen.form.gender_name]: "Gênero",
  [tokens.customerScreen.form.customer_notes]: "Notas do cliente",
  [tokens.customerScreen.form.customer_notes_description]:
    "Registre aqui as interações que você tem com o cliente",
  [tokens.customerScreen.form.created_date]: "Data do cadastro",
  [tokens.customerScreen.form.updated_date]: "Última alteração",
  [tokens.customerScreen.form.customer_receive_contacts]: "Aceita receber contatos.",
  [tokens.customerScreen.form.customer_receive_contacts_description]:
    "O cliente pode receber contatos de marketing e pesquisas.",
  [tokens.customerScreen.form.customer_active]: "Ativo",
  [tokens.customerScreen.form.customer_active_description]:
    "O cliente ainda não foi ativado pela plataforma ou está bloqueado.",
  [tokens.customerScreen.form.customer_not_register]: "Cliente não cadastrado.",
  [tokens.customerScreen.form.customer_not_register_description]:
    "Erro ao cadastrar cliente no gateway.",
  [tokens.customerScreen.form.btn_update_customer]: "Atualizar cliente",
  [tokens.customerScreen.form.btn_customer_cancel]: "Cancelar",
  [tokens.customerScreen.form.edit_customer]: "Editar cliente",
  [tokens.customerScreen.address.form.create_address]: "Adicionar endereço",
  [tokens.customerScreen.address.form.address_create_title]: "Novo Endereço",
  [tokens.customerScreen.address.form.address_title]: "Dados do endereço",
  [tokens.customerScreen.address.form.address_edit_title]: "Editar Endereço",
  [tokens.customerScreen.address.form.address_id]: "Código do endereço",
  [tokens.customerScreen.messages.customer_edit_error]: "Erro ao alterar dados do cliente!",
  [tokens.customerScreen.messages.customer_edit_success]: "Cliente atualizado com sucesso",
  [tokens.customerScreen.messages.address_error]: "Algo deu errado!",
  [tokens.customerScreen.messages.address_create_success]: "Endereço criado com sucesso",
  [tokens.customerScreen.messages.address_edit_success]: "Endereço alterado com sucesso",
  [tokens.customerScreen.address.form.street_name_label]: "Nome da rua",
  [tokens.customerScreen.address.form.number_label]: "Número",
  [tokens.customerScreen.address.form.neighborhood_label]: "Bairro",
  [tokens.customerScreen.address.form.complimentary_information_label]:
    "Informações complementares",
  [tokens.customerScreen.address.form.zipcode_label]: "CEP",
  [tokens.customerScreen.address.form.latitude_label]: "Latitude",
  [tokens.customerScreen.address.form.longitude_label]: "Longitude",
  [tokens.customerScreen.address.form.state_label]: "Estado",
  [tokens.customerScreen.address.form.country_label]: "País",
  [tokens.customerScreen.address.form.city_label]: "Cidade",
  [tokens.customerScreen.address.form.btn_edit_address]: "Atualizar endereço",
  [tokens.customerScreen.address.form.btn_save_address]: "Salvar endereço",
  [tokens.customerScreen.address.modal.title]: "Confirmação de exclusão!",
  [tokens.customerScreen.address.modal.description]:
    "Atenção! A ação a seguir é permanente. Deseja deletar o endereço de código :id",
  [tokens.customerScreen.contacts.contact_tile]: "Contatos",
  [tokens.customerScreen.contacts.contact_table.contact_id_column]: "Código",
  [tokens.customerScreen.contacts.contact_table.phone_area_column]: "Código de área",
  [tokens.customerScreen.contacts.contact_table.phone_code_column]: "DDD",
  [tokens.customerScreen.contacts.contact_table.phone_number_column]: "Telefone",
  [tokens.customerScreen.contacts.contact_table.talk_to_column]: "Responsável",
  [tokens.customerScreen.contacts.contact_table.email_column]: "E-mail",
  [tokens.customerScreen.contacts.contact_table.isMain_column]: "Contato principal",
  [tokens.customerScreen.contacts.contact_table.createAt_column]: "Criado em",
  [tokens.customerScreen.contacts.form.create_contact_title]: "Adicionar contato",
  [tokens.customerScreen.contacts.form.contacts_create_title]: "Novo Contato",
  [tokens.customerScreen.contacts.form.contacts_edit_title]: "Editar Contato",
  [tokens.customerScreen.contacts.form.contact_id]: "Código do contato",
  [tokens.customerScreen.messages.contacts_error]: "Algo deu errado!",
  [tokens.customerScreen.messages.contacts_create_success]: "Contato criado com sucesso",
  [tokens.customerScreen.messages.contacts_edit_success]: "Contato alterado com sucesso",
  [tokens.customerScreen.contacts.form.talk_to_label]: "Responsável",
  [tokens.customerScreen.contacts.form.phone_area_label]: "Códgio de área",
  [tokens.customerScreen.contacts.form.phone_code_label]: "DDD",
  [tokens.customerScreen.contacts.form.phone_number_label]: "Telefone",
  [tokens.customerScreen.contacts.form.email_label]: "E-mail",
  [tokens.customerScreen.contacts.form.main_contact_label]: "Contato principal",
  [tokens.customerScreen.contacts.form.main_contact_description]:
    "Ao escolher esta opção, você está designando esse contato como um dos principais contatos do cliente.",
  [tokens.customerScreen.contacts.form.btn_edit_contacts]: "Atualizar Contato",
  [tokens.customerScreen.contacts.form.btn_save_contacts]: "Salvar Contato",
  [tokens.customerScreen.contacts.form.contact_tile]: "Dados do contato",

  // Overview
  [tokens.overviewScreen.main_title]: "Visão geral",
  [tokens.overviewScreen.label_overview_period]: "Baseado no perído selecionado",
  [tokens.overviewScreen.customerSubscriptions.labelcustomerSubscriptions]: "Clientes cadastrados",
  [tokens.overviewScreen.customerSubscriptions.amount_customers]: "Clientes",
  [tokens.overviewScreen.customerSubscriptions.year]: "Ano",
  [tokens.overviewScreen.customerSubscriptions.labelThisYear]: "Este ano",
  [tokens.overviewScreen.customerSubscriptions.labelLastYear]: "Último ano",
  [tokens.overviewScreen.customerSubscriptions.months.jan]: "Jan",
  [tokens.overviewScreen.customerSubscriptions.months.feb]: "Fev",
  [tokens.overviewScreen.customerSubscriptions.months.mar]: "Mar",
  [tokens.overviewScreen.customerSubscriptions.months.apr]: "Abr",
  [tokens.overviewScreen.customerSubscriptions.months.may]: "Mai",
  [tokens.overviewScreen.customerSubscriptions.months.jun]: "Jun",
  [tokens.overviewScreen.customerSubscriptions.months.jul]: "Jul",
  [tokens.overviewScreen.customerSubscriptions.months.aug]: "Aug",
  [tokens.overviewScreen.customerSubscriptions.months.sep]: "Set",
  [tokens.overviewScreen.customerSubscriptions.months.oct]: "Out",
  [tokens.overviewScreen.customerSubscriptions.months.nov]: "Nov",
  [tokens.overviewScreen.customerSubscriptions.months.dec]: "Dez",

  [tokens.overviewScreen.latestTransactions.labelLastWeek]: "Última semana",

  [tokens.overviewScreen.latestTransactions.labelLatestTransactions]: "Últimas transações",
  [tokens.customerScreen.contacts.modal.title]: "Confirmação de exclusão!",
  [tokens.customerScreen.contacts.modal.description]:
    "Atenção! A ação a seguir é permanente. Deseja deletar o contato de código :id",
}
